<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="1300px">
      <v-card v-if="resolutionScreen >= 500">
        <v-card-title>
          <v-icon
            x-large
            :color="seticon(fileoutboxdata.data_type)[1]"
          >{{ seticon(fileoutboxdata.data_type)[0]}}</v-icon>
          &nbsp;{{fileoutboxdata.filename}}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>

          <v-row>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-format-list-numbered</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{fileoutboxdata.document_no}}</v-list-item-title>
                  <v-list-item-subtitle>Document no</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-calendar</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{setformatdate(fileoutboxdata.document_date)}}</v-list-item-title>
                  <v-list-item-subtitle>Document date</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-account-box</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{fileoutboxdata.sender_name}}</v-list-item-title>
                  <v-list-item-subtitle>Seller name</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="fileoutboxdata.relation_id !== ''">{{fileoutboxdata.relation_id}}</v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle>Relation id</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-file</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{fileoutboxdata.document_type}}</v-list-item-title>
                  <v-list-item-subtitle>Document type</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-calendar-import</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{formatdatetime(fileoutboxdata.cre_dtm)}}</v-list-item-title>
                  <v-list-item-subtitle>Receive date</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{fileoutboxdata.receiver_name}}</v-list-item-title>
                  <v-list-item-subtitle>Buyer name</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>insert_drive_file</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-if="fileoutboxdata.relation_name !== ''">{{fileoutboxdata.relation_name}}</v-list-item-title>
                  <v-list-item-title v-else>-</v-list-item-title>
                  <v-list-item-subtitle>Relation name</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <br />
          <!-- <div class="text-right pb-1">
         <v-btn depressed small :color="color.theme" :dark="color.darkTheme" @click="dialogUpload()">Upload file</v-btn>
          </div> -->
        <v-layout justify-center class="pa-4">
            <v-flex >
             <!-- <v-btn
             depressed
              small
              fab
              color="error"
              :dark="color.darkTheme" 
             >
             <v-icon>
                 delete
               </v-icon>
             </v-btn> -->
           </v-flex>
           <v-flex class="text-right pb-1">
             <!-- relation -->
             <v-btn
             v-if="fileoutboxdata.relation_id !== '' && fileoutboxdata.relation_name !== ''"
             small
              :color="color.theme"
              :dark="color.darkTheme"
              @click="opendialogrelation = true"             
             >
             relation
             </v-btn>
            <v-btn
             v-else-if="fileoutboxdata.relation_id === '' && fileoutboxdata.relation_name === ''"
             small
              :color="color.theme"
              :dark="color.darkTheme"
               @click="opendialogcreaterelation = true" 
             >
             create relation
             </v-btn>
            <!-- upload -->
            <v-btn
              class="mx-2"
              disabled
              small
              :color="color.theme"             
              @click="dialogUpload()"
            >Upload file</v-btn>

           </v-flex>
          </v-layout>
                             
          <v-data-table
            :headers="headers"
            :items="outboxdata"
            :items-per-page="5"
            :hide-default-footer="false"
            class="elevation-0"
            id="thiscontainer5"
          >
            <template v-slot:[`header.data_type`]="{ header }">
              <span :style="headerTable">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.filename`]="{ header }">
              <span :style="headerTable">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.document_no`]="{ header }">
              <span :style="headerTable">{{ header.text }}</span>
            </template>
            <!-- <template v-slot:header.document_date="{ header }">
              <span :style="headerTable">{{ header.text }}</span>
            </template> -->
            <template v-slot:[`header.document_type`]="{ header }">
              <span :style="headerTable">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.file_id`]="{ header }">
              <span :style="headerTable">{{ header.text }}</span>
            </template>
            <template v-slot:item="props">
              <tr>
                <td width="5%" class="text-center">
                  <v-icon large :color="seticon(props.item.data_type)[1]">{{ seticon(fileoutboxdata.data_type)[0]}}</v-icon>
                </td>
                <td width="20%" class="text-center">{{ props.item.filename}}</td>
                <td width="10%" class="text-center">{{ props.item.document_no }}</td>
                <!-- <td width="10%" class="text-center">{{ props.item.document_date }}</td> -->
                <td width="10%" class="text-center">{{ props.item.document_type }}</td>
                <td width="5%" class="text-center">
                  <v-menu>
                          <template v-slot:activator="{ on }">
                              <v-btn class="elevation-0" fab small v-on="on">
                                <!-- @click="downloadfile(props.item) -->
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                          </template>
                          <v-list>
                            <!-- @click=" opendialog = true, newdata=props.item " -->
                            <v-list-item  @click=" opendialog = true, newdata=props.item ">
                                <v-list-item-icon>
                                  <v-icon>
                                    mdi-information
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{$t('myinboxPage.Filedetails')}}
                                  </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item disabled>
                                <v-list-item-icon>
                                  <v-icon>
                                    mdi-download-multiple
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ $t('dowloads')}}
                                  </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" :dark="color.darkTheme" @click="$emit('closedialog')">close</v-btn>
        </v-card-actions>
      </v-card>
      <!-- phone -->
      <v-card v-else>
        <v-card-title>Detail</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list-item>
              <v-list-item-icon>
                <v-icon>{{ seticon(fileoutboxdata.data_type)[0]}}</v-icon>
              </v-list-item-icon>
             <v-list-item-content>
               <v-list-item-title>{{fileoutboxdata.document_no}}</v-list-item-title>
               <v-list-item-subtitle>Document no</v-list-item-subtitle>
             </v-list-item-content>
             </v-list-item>
             <v-divider></v-divider>
             <v-list-item>
            <v-list-item-icon><v-icon>mdi-file</v-icon></v-list-item-icon>   
             <v-list-item-content>
               <v-list-item-title>{{fileoutboxdata.document_type}}</v-list-item-title>
               <v-list-item-subtitle>Document type</v-list-item-subtitle>
             </v-list-item-content>
             </v-list-item>
             <v-divider></v-divider>
             <v-list-item>
             <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
             <v-list-item-content>
               <v-list-item-title>{{setformatdate(fileoutboxdata.document_date)}}</v-list-item-title>
               <v-list-item-subtitle>Document date</v-list-item-subtitle>
             </v-list-item-content>
             </v-list-item>
             <v-divider></v-divider>
              <v-list-item>
               <v-list-item-icon><v-icon>mdi-account-box</v-icon></v-list-item-icon>
             <v-list-item-content>
               <v-list-item-title>{{fileoutboxdata.sender_name}}</v-list-item-title>
               <v-list-item-subtitle>Seller name</v-list-item-subtitle>
             </v-list-item-content>
             </v-list-item>
             <v-divider></v-divider>
             <v-list-item>
               <v-list-item-icon><v-icon>mdi-account-circle</v-icon></v-list-item-icon>
             <v-list-item-content>
               <v-list-item-title>{{fileoutboxdata.receiver_name}}</v-list-item-title>
               <v-list-item-subtitle>Buyer name</v-list-item-subtitle>
             </v-list-item-content>
             </v-list-item>
             <v-divider></v-divider>
             <v-list-item>
             <v-list-item-icon><v-icon>mdi-calendar-import</v-icon></v-list-item-icon>
             <v-list-item-content>
               <v-list-item-title>{{formatdatetime(fileoutboxdata.cre_dtm)}}</v-list-item-title>
               <v-list-item-subtitle>Receive date</v-list-item-subtitle>
             </v-list-item-content>
             </v-list-item>
             <v-divider></v-divider>
             <br />

             <div class="text-right pb-5">
               <v-btn
             v-if="fileoutboxdata.relation_id !== '' && fileoutboxdata.relation_name !== ''"
             small
              :color="color.theme"
              :dark="color.darkTheme"
              @click="opendialogrelation = true"             
             >
             relation
             </v-btn>
            <v-btn
             v-else-if="fileoutboxdata.relation_id === '' && fileoutboxdata.relation_name === ''"
             small
              :color="color.theme"
              :dark="color.darkTheme"
               @click="opendialogcreaterelation = true" 
             >
             create relation
             </v-btn>
               <v-btn class="mx-2" small :color="color.theme" :dark="color.darkTheme" @click="dialogUpload()">Upload file</v-btn>
            </div>
            <v-list two-line class="mb-6 pb-6 elevation-1" id="thiscontainer4" >
              <v-list-item
                        v-for="item in outboxdata"
                        :key="item.title"
                        style="cursor: pointer;"                        
                      >
                      <v-list-item-avatar>
                          <v-icon
                            large
                            :color="seticon(item.data_type)[1]"
                            style="font-weight: 100"
                          >{{ seticon(item.data_type)[0]}}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content >
                          <v-list-item-title v-text="item.filename"></v-list-item-title>
                          <v-list-item-subtitle v-text="item.document_no"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                        <v-menu>
                          <template v-slot:activator="{ on }">
                              <v-btn class="elevation-0" fab small v-on="on">
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="opendialog = true , newdata=item">
                                <v-list-item-icon>
                                  <v-icon>
                                    mdi-information
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{$t('myinboxPage.Filedetails')}}
                                  </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item disabled>
                                <v-list-item-icon>
                                  <v-icon>
                                    mdi-download-multiple
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                  {{ $t('dowloads')}}
                                  </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        </v-list-item-action>
              </v-list-item>
            </v-list>
        </v-card-text>
         <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" :dark="color.darkTheme"  @click="$emit('closedialog')">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialoguploadrelated
      :show="opendialogupload"
      @closedialogupload="opendialogupload = false"
      @opendialogupload="opendialogupload = true"
    ></dialoguploadrelated>
    <dialogdetailfile :show ="opendialog" :filedata ="newdata" @closedialog="opendialog = false" />
    <relationoutbox
       :show="opendialogrelation"
       :filedata="filedata"
       :fileoutboxdata="fileoutbox"
       @loaddata="loaddata()"
      @closedialog="opendialogrelation = false"
    ></relationoutbox>
    <createrelationoutbox
      :show="opendialogcreaterelation" :fileoutbox="fileoutbox"
      @closedialog="opendialogcreaterelation = false"
      @checkparameter="openparameter"
    ></createrelationoutbox>
  </v-row>
</template> 

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const dialoguploadrelated = () => import("@/components/optional/dialog-uploadrelatedoutbox");
const dialogdetailfile = ()=> import("@/components/optional/dialog-detailfile");
const relationoutbox = () => import("@/components/optional/dialog-relationoutbox");
const createrelationoutbox = () => import("@/components/optional/dialog-createrelationoutbox");
export default {
  props: ["show","fileoutbox","filedata"],
  components: { dialoguploadrelated,dialogdetailfile ,relationoutbox ,createrelationoutbox},
  data: function() {
    return {
      fileoutboxdata:[],
      opendialogcreaterelation:false,
      opendialogrelation:false,
      newdata:[],
      opendialog:false,
      outboxdata:[],
      opendialogupload: false,
      headers: [
        {
          text: "#",
          align: "left",
          value: "document_type",
          sortable: false,
          width: "5%"
        },
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "filename",
          width: "20%"
        },
        {
          text: "Document no",
          value: "document_no",
          align: "left",
          sortable: false,
          width: "10%"
        },
        // {
        //   text: "Document date",
        //   align: "center",
        //   value: "document_date",
        //   sortable: false,
        //   width: "10%"
        // },
        {
          text: "Document type",
          align: "left",
          value: "document_type",
          sortable: false,
          width: "10%"
        },
        {
          text: "Option",
          align: "left",
          value: "file_id",
          sortable: false,
          width: "7%"
        }
      ],
      desserts: [
        {
          filename: "Testonebox1",
          document_no: 159,
          document_date: "01/07/2020",
          document_type: "ใบกำกับภาษี"
        },
        {
          filename: "Testonebox2",
          document_no: 159,
          document_date: "30/06/2020",
          document_type: "ใบกำกับภาษี"
        },
        {
          filename: "Testonebox3",
          document_no: 159,
          document_date: "30/06/2020",
          document_type: "ใบกำกับภาษี"
        },
        {
          filename: "Testonebox4",
          document_no: 159,
          document_date: "29/06/2020",
          document_type: "ใบกำกับภาษี"
        },
        {
          filename: "Testonebox5",
          document_no: 159,
          document_date: "28/06/2020",
          document_type: "ใบกำกับภาษี"
        }
      ]
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId"
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.loaddata();
          this.checkfileinbox();
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      }
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    }
  },
  methods: {
    openparameter(value){
      this.fileoutboxdata.relation_id = value.relation_id
      this.fileoutboxdata.relation_name = value.relation_name
      // console.log("openparameter",value);
      this.loaddata();
      this.opendialogcreaterelation = false;

    },
    checkfileinbox(){
      this.fileoutboxdata = this.fileoutbox;
    },
   async loaddata(){
    let payload;
    payload={
       inbox_id : this.fileoutbox.inbox_id
    }
    console.log("pay",payload);
    let auth = await gbfGenerate.generateToken();
   await this.axios
        .post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/get_relation_inbox",
          payload,{headers: { Authorization: auth.code },},
        )
        .then(response =>{
          console.log("response====>>",response);
          if (response.data.status === "OK") {
             console.log("ok");
            
             this.outboxdata = response.data.data["outbox"]
             console.log("outbox",this.outboxdata);
          }
        }).catch(error =>{
          console.log(error);
        })
    },
    dialogUpload() {
      console.log("dialogupload");
      this.opendialogupload = true;
    },
    seticon(parameter) {
      // console.log(parameter);
      // let parameter_ = parameter.toLowerCase()
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      }else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
       else {
        dataicon = ["mdi-file-question-outline", "black"];
      }

      return dataicon;
    },
    formatdatetime(_datetime) {
      if(_datetime === "" || _datetime === null || _datetime === undefined){
        return "-";
      }else{
      let dateyear =
        _datetime.split("")[0] +
        _datetime.split("")[1] +
        _datetime.split("")[2] +
        _datetime.split("")[3];
      let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
      let dateday = _datetime.split("")[6] + _datetime.split("")[7];
      let hour = _datetime.split("")[8] + _datetime.split("")[9];
      let minute = _datetime.split("")[10] + _datetime.split("")[11];
      let second = _datetime.split("")[12] + _datetime.split("")[13];

      return (
        dateday +
        "/" +
        datemonth +
        "/" +
        dateyear +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
      }
    },
    setformatdate(_date) {
      if(_date === "" || _date === null || _date === undefined){
        return "-";
      }else{
      let data =
        _date.substring(8, 6) +
        "/" +
        _date.substring(6, 4) +
        "/" +
        _date.substring(0, 4);
      return data || "-";
    }
    }
  }
};
</script>